<template>
<div>
  <div class="table">
    <Table border :columns="columns" :loading="loading" :data="dataList"></Table>
  </div>
  <div class="page">
    <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "stuTask.vue",
  data(){
    return{
      columns:[
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: '课程',
          key: 'id',
          render(h,params){
            return h('span',params.row.generate_course && params.row.generate_course.name)
          }
        },
        {
          title: '任务名称',
          key: 'name',

        },
        {
          title: '下发老师',
          key: 'id',
          render(h,params){
            return h('span',params.row.user && params.row.user.nickname)
          }
        },
        // {
        //   title: '状态',
        //   key: 'id',
        //   render:(h,params)=>{
        //     return h('span',this.doneStatus[params.row.is_done])
        //   }
        // },
        {
          title: '创建时间',
          render:(h,params)=>{
            return h('span', util.timeFormatter(new Date(+params.row.created_at*1000), 'yyyy-MM-dd hh:mm'));
          }
        },
        {
          title: '操作',
          render:(h,params)=>{
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.goTrain(params.row);
                  }
                }
              }, '章节练习'),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.goAllTrain(params.row);
                  }
                }
              }, '去刷题')
            ]);
          }
        },
      ],
      loading:false,
      dataList:[],
      total:0,
      page:1,
      pageSize:10,
      doneStatus:{},
    }
  },
  props:{
    courseId:{
      type:String,
      default:''
    }
  },
  created(){
    this.getList();
  },
  methods:{
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        course_id:this.courseId
      };
      this.loading = true;
      this.api.course.exerciseMList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.dataList = res.list;
        this.total = Number(res.count);

        this.doneStatus = res.doneStatus;
        console.log(this.doneStatus,'this.doneStatus')

      }).catch((err)=>{
        this.loading = false;
      })
    },
    goTrain(data){
      this.$router.push({
        path:'/trainingcamp/task-train-detail',
        query:{
          exerciseId:data.id,
          courseId:data.generate_course.id,
        }
      })
    },
    goAllTrain(data){
      this.$router.push({
        path:'/trainingcamp/trainDetail',
        query:{
          // id:this.categoryId,
          dataSort:'1',
          train_id:data.train.id
        }
      })
    }
  }
}
</script>

<style scoped>
.page{
  margin-top: 20px;
  text-align: right;
}
</style>
