<template>
<div class="course-list">
  <div class="search">
    <div style="position: relative">
      <Spin fix v-if="loading"></Spin>
      <div v-else class="search-item">
        <div class="name">
          {{$t('trainingcamp_exam_create_category_first')}}:
        </div>
        <div class="cont">
          <div class="values">
            <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeFirstCategory(item)">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative">
      <Spin fix v-if="secondLoading"></Spin>
      <div class="search-item" v-if="secondCategoryList.length">
        <div class="name">
          {{$t('trainingcamp_exam_create_category_second')}}:
        </div>
        <div class="cont">
          <div class="values" >
            <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSecondSearch(item)">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="dataList.length">
    <div class="course-list" >
      <div  class="course-list-item" v-for="(item,index) in dataList" :key="index" @click="goMaterialInfo(item)">
        <div class="course-list-item-info" :class="isStudent ? '' : 'hoverC'" @click="goInfo(item)">
          <img :src="item.map && item.map.book_img" width="190" height="106" />
          <div>
            <p class="course-list-item-info-tit" :title="item.name">{{ item.name }}</p>
<!--            <p>{{ item.category.name }}</p>-->
            <div class="course-list-item-info-nums">
              <p class="course-list-item-info-num">
                课时 {{ item.lesson_count}}<span>|</span>{{ lesson_levels[item.lesson_level] }}
              </p>
              <el-button type="primary" size="small" v-if="!isStudent">查看详情</el-button>
            </div>
            <div class="course-list-item-btns" v-if="isStudent">
              <Button type="primary" @click="goMaterialInfo(item)">查看详情</Button>
              <Button @click.stop="goMaterialSelfExam(item)">自测模考</Button>
            </div>
          </div>
        </div>
        <div class="course-interact-status" v-if="isStudent && item.is_show_interact == '1'">互动答题进行中</div>
        <!--          <div class="course-list-item-btns" v-if="isStudent">-->
        <!--            <Button type="primary" @click="goMaterialInfo(sItem)">去练习</Button>-->
        <!--            <Button @click.stop="goMaterialSelfExam(sItem)">自测模考</Button>-->
        <!--          </div>-->
        <!--          <div class="course-list-item-btns" v-else>-->
        <!--            <Button type="primary" @click="goTask(sItem)">学习任务</Button>-->
        <!--&lt;!&ndash;            <Button @click="goMaterialCreate(sItem,1,2)">模考组卷</Button>&ndash;&gt;-->
        <!--            &lt;!&ndash;          <Button @click="goAnalysis(sItem)">学习分析</Button>&ndash;&gt;-->
        <!--            <Button @click="goSource(sItem)">资源管理</Button>-->
        <!--&lt;!&ndash;            <Button @click="goInfo(sItem)">课程详情</Button>&ndash;&gt;-->
        <!--          </div>-->
      </div>
<!--      <div v-for="sItem in item" :key="item.id">-->
<!--        -->
<!--      </div>-->

    </div>
    <div class="page">
      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
    </div>
  </div>

  <div v-if="!dataList.length && !courseLoading" class="no-data">
    <img src="../../../../assets/images/new_icon/kong.png" width="355" height="190" />
    <p>暂无数据</p>
  </div>
</div>
</template>

<script>
export default {
  name: "courseList",
  data(){
    return{
      categoryList:[],
      curCategoryId:'',
      loading:false,
      secondCategoryList:[],
      cursecondCategoryId:'',
      secondLoading:false,
      dataList:[],
      total:0,
      page:1,
      pageSize:10,
      courseLoading:true,
      lesson_levels:{}
    }
  },
  props:{
    isStudent:{
      type:Boolean,
      default:false,
    }
  },
  created(){
    this.getCategoryList();
  },
  methods:{
    getCategoryList(){
      let data = {
        is_show_has_third:1,
        type:4,
      };
      this.loading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.loading = false;
        //一级标签
        this.categoryList = [
          {
            name:'全部',
            id:-1
          },
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id ;

        this.secondCategoryList = [
          {
            name:'全部',
            id:-1
          },
          ...res.categorySecondList
        ];
        this.cursecondCategoryId = this.secondCategoryList[0].id;
        this.getList();

      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeFirstCategory(data){
      this.curCategoryId = data.id;
      this.cursecondCategoryId = '-1';
      this.getSecondList();
      this.getList();
    },
    getSecondList(){
      let data = {
        category_id:this.curCategoryId,
        type:4,
        is_show_has_third:1
      };
      this.secondLoading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.secondLoading = false;
        //一级标签
        this.secondCategoryList = [
          {
            name:'全部',
            id:-1
          },
          ...res.categorySecondList
        ];
      }).catch((err)=>{
        this.secondLoading = false;
      })
    },
    changeSecondSearch(data){
      this.cursecondCategoryId = data.id;
      this.getList();
    },
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        status:0,
        user_type:this.isStudent ? 0 : 1
      };
      if(this.cursecondCategoryId > -1){
        params.category_id = this.cursecondCategoryId;
      }else if(this.curCategoryId > -1){
        params.category_id = this.curCategoryId;
      }
      if(this.isStudent){
        params.with_course_interact_task = 1;
      }

      this.api.course.courseMList(params).then((res)=>{
        this.courseLoading = false;
        this.dataList = res.list;
        // if(res.list.length){
        //   for(let i=0;i<res.list.length;i+=3){
        //     this.dataList.push(res.list.slice(i,i+3));
        //   }
        //   if(this.dataList[this.dataList.length - 1].length <3){
        //     for(let i = this.dataList[this.dataList.length - 1].length ;i<3;i++){
        //       this.dataList[this.dataList.length - 1].push({});
        //     }
        //   }
        // }

        this.total = Number(res.count);
        this.statuses = res.statuses;
        this.lesson_levels = res.lesson_levels;
      }).catch((err)=>{
        this.courseLoading = false;
      })

    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    goSource(data){
      this.$router.push({
        path:'/course/source-manage',
        query:{
          id:data.id,
        }
      })
    },
    goAnalysis(data){
      this.$router.push({
        path:'/course/analysis',
        query:{
          id:data.id,
        }
      })
    },
    goInfo(data){
      if(!this.isStudent){
        this.$router.push({
          path:'/teacher/course/detail',
          query:{
            id:data.id,
            mapId:data.map_id,
          }
        })
      }
    },
    goMaterialCreate(data,type,generateDataRule){
      let params = {
        op:'create',
        generate_type:0,
        generate_id:data.id,
        type:1,
        from_type:5,
        from_id:data.id,
        generate_data_rule:2,
      };
      this.api.dataset.trainPublish(params).then((res)=>{
        this.$router.push({
          path:'/trainingcamp/materialCreate',
          query:{
            trainId:res.train_id,
            mapId:data.map_id,
            createType:3  //1 教材 2 认证 3 课程
          }
        })
      })
    },
    goTask(data){
      this.$router.push({
        path:'/course/task/list',
        query:{
          id:data.id,
          mapId:data.map_id,
        }
      })
    },
    goMaterialInfo(data){
      if(this.isStudent){
        this.$router.push({
          path:'/trainingcamp/chapters',
          query:{
            courseId:data.id,
          }
        })
      }

    },
    goMaterialSelfExam(data){
      this.$router.push({
        path:'/trainingcamp/material-self-exam',
        query:{
          mapId:data.map_id,
          categoryId:data.category_id,
          courseId: data.id,
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.course-list{
  .search{
    margin-top: 20px;
    font-size: 14px;
    color: #333333;
    .search-item{
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
        font-weight: bold;
      }
      .cont{
        padding: 5px 0;
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            //margin: 0 10px 10px 5px;
            margin-right: 20px;
            margin-bottom: 10px;
            cursor: pointer;
          }
          >div.active{

            font-weight: bold;
            color: #4A79FF;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .course-list{
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    >div{

    }
    .course-list-item{
      margin-bottom: 20px;
      //padding: 12px;

      background: #F6F7FA;
      border-radius: 6px;
      width: 284px;
      margin-right: 12px;
      position: relative;
      .course-interact-status{
        position: absolute;
        left: 0;
        top: 14px;
        width: 104px;
        height: 26px;
        background: #FF8400;
        border-radius: 0px 13px 13px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
      }

      .course-list-item-info{
        //display: flex;
        //justify-content: flex-start;
        color: #666666;
        cursor: pointer;
        >img{
          width: 284px;
          height: 156px;
          border-radius: 6px;
        }
        .course-list-item-info-tit{
          margin-top: 12px;
          font-weight: bold;
          color: #333333;
          line-height: 20px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          white-space: nowrap;
        }
        >div{
          padding: 0 16px 20px 16px;
          .course-list-item-info-nums{
            margin-top: 20px;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .course-list-item-info-num{
              >span{
                padding: 0 10px;
              }

            }
          }
        }
      }
      .hoverC:hover{
        .course-list-item-info-tit{
          color: #5578F6;
        }
      }
      .course-list-item-btns{
        margin-top: 22px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        >button{
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .page{
    margin-top: 20px;
    text-align: center;
  }
  .no-data{
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
</style>
