<template>
<div class="student-list">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="student-list-nr">
    <div class="train-tab">
      <p :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</p>
    </div>
    <CourseList :isStudent="true" v-show="curTab.id == 1"></CourseList>
<!--    <StuTask  v-show="curTab.id == 2"></StuTask>-->
  </div>
</div>
</template>

<script>
import CourseList from './components/list/courseList';
import StuTask from './components/list/stuTask';
export default {
  name: "studentList",
  data(){
    return{
      tabList:[
        {
          name:'课程学习',
          id:'1',
          tab:'list'
        },
        // {
        //   name:'课程任务',
        //   id:'2',
        //   tab:'task'
        // }
      ],
      curTab:{
        name:'课程学习',
        id:'1',
        tab:'list'
      },
    }
  },
  components:{
    CourseList,
    StuTask
  },
  created(){
    let tab = this.$route.query.tab || '';
    if(tab){
      this.curTab = this.tabList.filter((item)=>{
        return item.tab == tab;
      })[0];
    }
  },
  methods:{
    changeTab(data){
      this.curTab = data;
      this.$router.push({
        path:'/course/student-list',
        query:{
          tab:this.curTab.tab
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.student-list{
  padding: 20px;
  .student-list-nr{
    padding: 20px;
    background-color: #FFFFFF;

    .train-tab{
      margin-bottom: 20px;

      border-bottom: 1px solid #EEEEEE;
      font-size: 16px;
      color: #666666;
      display: flex;
      justify-content: flex-start;
      >p{
        padding-bottom: 10px;
        margin-right: 30px;
        cursor: pointer;
      }
      .active{
        font-weight: bold;
        color: #4A79FF;
        border-bottom: 4px solid #5782FF;
      }
    }
  }
}
</style>
